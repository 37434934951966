import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/Layout';
import ProductTabs from '../components/Products/ProductTabs';
import PhoneInputComponent from '../components/PhoneInput';

import ReactMarkdown from 'react-markdown';
import CustomLink from '../components/Link';
import SEO from '../components/Seo';
import Hero from '../components/Hero';
import SliderComponent from '../components/Slider';
import FeatureBoxes from '../components/FeatureBoxes';
import Testimonials from '../components/Testimonials';
import MembershipCards from '../components/MembershipCards';
import Protect from 'react-app-protect';

const Index = (props) => {
  if (!props) return null;

  const homepageContent = props.pageContext.data;
  const footerContent = props.pageContext.footer.node;
  const headerContent = props.pageContext.header;
  const translation = props.pageContext.translation;
  const localSeo = props.pageContext.data.seo && props.pageContext.data.seo;
  const slug = props.pageContext.data.slug;

  return (
    <>
      {typeof window !== 'undefined' && (
        <Protect sha512="5F8A01670BDECBAFD1D1D5D23E3CB2205B65FE9BABA76542EDB8B36EDD69215E8042015790D3ED2C1A6E3CB949966991C8BECF34EEB02ABA9FF1EDE860EB3E38">
          <Layout
            themeColor="mode--light"
            currentLocation={props.path}
            currentNodeLocale={homepageContent.node_locale}
            footerContent={footerContent}
            headerContent={headerContent}
            translation={translation}
          >
            {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}

            {/* Hero Section */}
            {homepageContent.hero && (
              <Hero data={homepageContent.hero} currentNodeLocale={homepageContent.node_locale} />
            )}

            {/* Membership Card Slider */}
            {homepageContent.membershipCards && <MembershipCards data={homepageContent.membershipCards} />}

            {/* Feature boxes */}
            {homepageContent.legalExperts && <FeatureBoxes data={homepageContent.legalExperts} />}

            {/* Testimonials */}
            {homepageContent.legalExperts && <Testimonials data={homepageContent.testimonials} />}

            {/* Single features */}
            {homepageContent.inAppFeatures && (
              <section className="section">
                <div className="container">
                  {homepageContent.inAppFeatures.map((feature, index) => {
                    return (
                      <div key={index} className="feature">
                        <div className="feature__image">
                          <img
                            src={feature.image.file.url}
                            alt={feature.image.description ? feature.image.description : ''}
                          />
                        </div>
                        <div className="feature__text">
                          {documentToReactComponents(feature.content.json)}
                          <div className="text-center lg:text-left">
                            <CustomLink link={feature.link} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>
            )}

            {/* Partner Slider */}
            {homepageContent.ourPartners && <SliderComponent data={homepageContent.ourPartners} />}

            {/* Pricing tables */}
            {homepageContent.products && (
              <section className="section">
                <div className="container">
                  <div className="section__heading">
                    <ReactMarkdown
                      source={homepageContent.products.title.childMarkdownRemark.rawMarkdownBody}
                      disallowedTypes={['paragraph']}
                      unwrapDisallowed={true}
                    />
                  </div>
                  <div>
                    <ProductTabs
                      productsInfo={homepageContent.products}
                      currentNodeLocale={homepageContent.node_locale}
                      compareSlug={slug}
                    />
                  </div>
                </div>
              </section>
            )}

            {/* One Time Consultation */}
            {homepageContent.oneTimeConsultation &&
              homepageContent.oneTimeConsultation.contentImage.map((content) => {
                <section className="section">
                  <div className="container">
                    <div className="feature">
                      <div className="feature__image">
                        <img
                          src={content.image.file.url}
                          alt={content.image.description ? content.image.description : ''}
                        />
                      </div>
                      <div className="feature__text">
                        {documentToReactComponents(content.content.json)}
                        <div className="text-center lg:text-left">
                          <CustomLink link={content.link} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>;
              })}

            {/* How to steps */}
            {homepageContent.howToUseTheApp && (
              <section className="section">
                <div className="container">
                  <div className="section__heading">
                    <ReactMarkdown
                      source={homepageContent.howToUseTheApp.content.childMarkdownRemark.rawMarkdownBody}
                      disallowedTypes={['paragraph']}
                      unwrapDisallowed={true}
                    />
                  </div>
                  <div className="steps">
                    <ol className="how-to">
                      {homepageContent.howToUseTheApp.referenceOne.map((info, index) => {
                        return (
                          <li key={index} className="how-to__step">
                            {documentToReactComponents(info.content.json)}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                  <div className="flex justify-center">
                    {homepageContent.howToUseTheApp.referenceTwo.images.map((image, index) => {
                      return (
                        <div className="step__apps" key={index}>
                          <a href={homepageContent.howToUseTheApp.referenceTwo.imageLinks[index]}>
                            <img
                              className="w-32 shadow-lg"
                              src={image.file.url}
                              key={index}
                              alt={image.description ? image.description : ''}
                            />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            )}

            {/* Why us */}
            {homepageContent.whyGaius && (
              <section className="section">
                <div className="container">
                  <div className="grid gap-md lg:grid-cols-2 text-center lg:text-left items-center">
                    <img
                      className="rounded-lg m-auto shadow-lg"
                      src={homepageContent.whyGaius.image.file.url}
                      alt={homepageContent.whyGaius.image.description ? homepageContent.whyGaius.image.description : ''}
                    />
                    <div>
                      {documentToReactComponents(homepageContent.whyGaius.content.json)}

                      <CustomLink link={homepageContent.whyGaius.link} />
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* FAQ */}
            {homepageContent.faQs && (
              <section className="section section--underlay">
                <div className="container">
                  <div className="section__heading">
                    <ReactMarkdown
                      source={homepageContent.faQs.content.childMarkdownRemark.rawMarkdownBody}
                      disallowedTypes={['paragraph']}
                      unwrapDisallowed={true}
                    />
                  </div>
                  <div>
                    {homepageContent.faQs.reference.map((faq, index) => {
                      return (
                        <div key={index} className="card faq">
                          <div className={`card__content ${faq.alignContent ? 'text-center' : ''}`}>
                            {documentToReactComponents(faq.content.json)}
                            <CustomLink link={faq.link} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            )}

            {/* Get the app section */}
            {homepageContent.getTheApp && (
              <section className="section">
                <div className="container ">
                  <div className="section__heading ">
                    <ReactMarkdown
                      source={homepageContent.getTheApp.content.childMarkdownRemark.rawMarkdownBody}
                      disallowedTypes={['paragraph']}
                      unwrapDisallowed={true}
                    />
                  </div>
                  <div className="app-buttons">
                    {homepageContent.getTheApp.images.map((image, index) => {
                      return (
                        <div key={index} className="app-buttons__item">
                          <a href={homepageContent.getTheApp.imageLinks[index]}>
                            <img src={image.file.url} key={index} alt={image.description ? image.description : ''} />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mx-auto text-center hidden lg:block">
                    <PhoneInputComponent
                      phoneInputData={homepageContent.hero.phoneInput}
                      currentNodeLocale={homepageContent.node_locale}
                    />
                  </div>
                </div>
              </section>
            )}

            {/* Startup accelerators */}
            {homepageContent.startUpAccelerators && <SliderComponent data={homepageContent.startUpAccelerators} />}
          </Layout>
        </Protect>
      )}

      {/* my-selector */}
      <div className="xl:block ml-4" />
    </>
  );
};

export default Index;
