import React from 'react';
import ReactMarkdown from 'react-markdown';
import { sliderPartners } from '../config/slider';
import Slider from 'react-slick';

const SliderComponent = ({ data, cardSlider }) => {
  return (
    <section className="section section--notoppadding section--fullwidth section--fade">
      <div className="container">
        <div className="section__heading ">
          <ReactMarkdown
            source={data.title.childMarkdownRemark.rawMarkdownBody}
            disallowedTypes={['paragraph']}
            unwrapDisallowed={true}
          />
        </div>
        <Slider {...sliderPartners} className="slick-slider--center">
          {data.images.map((partner, index) => {
            return (
              <div
                key={index}
                className="flex-shrink-0 w-48 max-w-xxs box-border flex items-center px-lg opacity-50 hover:opacity-100 transition transition-all duration-300"
              >
                <img src={partner.file.url} alt={partner.description ? partner.description : ''} />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default SliderComponent;
