import React from 'react';
import ReactMarkdown from 'react-markdown';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { sliderTestimonials } from '../config/slider';
import Slider from 'react-slick';

const Testimonials = (props) => {
  const { data } = props;
  return (
    <section className="section section--fullwidth section--fade">
      <div className="container">
        <div className="section__heading ">
          <ReactMarkdown
            source={data.content.childMarkdownRemark.rawMarkdownBody}
            disallowedTypes={['paragraph']}
            unwrapDisallowed={true}
          />
        </div>
        <Slider {...sliderTestimonials} className="slick-slider--equal-height">
          {data.reference.map((testimonial, index) => {
            return (
              <div className="pl-md" key={index}>
                <div className="card mb-md w-full flex">
                  <blockquote className="blockquote text-center flex flex-col">
                    {documentToReactComponents(testimonial.customerFeedback.json)}
                    <footer>
                      <figure>
                        {testimonial.customerPicture && (
                          <img
                            className="blockquote__avatar"
                            src={testimonial.customerPicture.file.url}
                            alt={testimonial.customerPicture.description ? testimonial.customerPicture.description : ''}
                          />
                        )}
                        <figcaption>
                          <cite className="blockquote__cite">{testimonial.customerName}</cite>
                        </figcaption>
                      </figure>
                    </footer>
                  </blockquote>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
