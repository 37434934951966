import React from 'react';
import ReactMarkdown from 'react-markdown';
import { membershipCards } from '../config/slider';
import Slider from 'react-slick';

const MembershipCards = ({ data, cardSlider }) => {
  return (
    <section className="section section--notoppadding section--fullwidth section--fade">
      <div className="container">
        <div className="section__heading ">
          <ReactMarkdown
            source={data.title.childMarkdownRemark.rawMarkdownBody}
            disallowedTypes={['paragraph']}
            unwrapDisallowed={true}
          />
        </div>
        <Slider {...membershipCards} className="slick-slider--center">
          {data.images.map((partner, index) => {
            return (
              <div key={index} className="flex-shrink-0 max-w-xxs box-border flex items-center">
                <img src={partner.file.url} alt={partner.description ? partner.description : ''} />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default MembershipCards;
