import React from 'react';
import ReactMarkdown from 'react-markdown';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import CustomLink from '../components/Link';

const FeatureBoxes = (props) => {
  const { data } = props;
  return (
    <section className="section ">
      <div className="container">
        <div className="section__heading ">
          <ReactMarkdown
            source={data.content.childMarkdownRemark.rawMarkdownBody}
            disallowedTypes={['paragraph']}
            unwrapDisallowed={true}
          />
        </div>
        <div className="grid gap-md md:grid-cols-2 lg:grid-cols-4">
          {data.reference.map((appFeature, index) => {
            return (
              <div className="mb-md text-center flex flex-col" key={index}>
                <img
                  className="block w-24 mx-auto mb-base"
                  src={appFeature.image.file.url}
                  alt={appFeature.description ? appFeature.description : ''}
                />
                <div className="flex-1">{documentToReactComponents(appFeature.content.json)}</div>
                <CustomLink link={appFeature.link} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FeatureBoxes;
