export const sliderTestimonials = {
  dots: false,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  speed: 7000,
  autoplaySpeed: 0,
  pauseOnHover: false,
  pauseOnFocus: true,
  swipe: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ],
};

export const sliderPartners = {
  dots: false,
  arrows: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  speed: 7000,
  autoplaySpeed: 0,
  pauseOnHover: true,
  pauseOnFocus: true,
  swipe: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export const startupAccelerators = {
  dots: false,
  arrows: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  speed: 7000,
  autoplaySpeed: 0,
  pauseOnHover: true,
  pauseOnFocus: true,
  swipe: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export const membershipCards = {
  dots: false,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  speed: 10000,
  autoplaySpeed: 0,
  pauseOnHover: true,
  pauseOnFocus: true,
  swipe: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
